var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.optionsPeriod ? _c('div', [!_vm.webOnly && _vm.options ? _c('b-card', {
    staticClass: "border mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "body-class": "py-1 px-2"
    }
  }, [_c('h4', {
    staticClass: "font-weight-bolder text-dark"
  }, [_vm._v(" Chi tiết gói ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "40px"
    }
  }, _vm._l(_vm.chunk(_vm.options, Math.max(5, Math.round(_vm.options.length / 2))), function (listItem, listIndex) {
    return _c('div', {
      key: listIndex
    }, _vm._l(listItem, function (_ref, optIndex) {
      var description = _ref.description,
        value = _ref.value;
      return _c('div', {
        key: "".concat(listIndex, "_").concat(optIndex),
        staticStyle: {
          "width": "100%"
        }
      }, [_c('div', {
        staticClass: "d-flex-between font-weight-bolder",
        staticStyle: {
          "gap": "40px"
        }
      }, [_c('div', [_vm._v(_vm._s(description) + ":")]), typeof value === 'number' ? _c('div', [_c('span', {}, [_vm._v(_vm._s(_vm.formatCurrency(value)))])]) : typeof value === 'boolean' ? _c('div', [_c('feather-icon', {
        class: value ? 'text-success' : 'text-danger',
        attrs: {
          "icon": value ? 'CheckIcon' : 'XIcon',
          "size": "16"
        }
      })], 1) : typeof value === 'string' ? _c('div', [_c('span', {}, [_vm._v(_vm._s(value))])]) : _vm._e()])]);
    }), 0);
  }), 0)]) : _vm.webOnly ? _c('BAlert', {
    staticClass: "p-1 px-2",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" Hỗ trợ setup web bán lẻ. ")]) : !_vm.options ? _c('BAlert', {
    staticClass: "p-1 px-2",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Lỗi, không có mô tả ")]) : _vm._e()], 1) : _c('div', [_c('b-card', {
    staticClass: "border mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "body-class": "py-1 px-2"
    }
  }, [_c('h4', {
    staticClass: "font-weight-bolder text-dark"
  }, [_vm._v(" Chi tiết sử dụng gói ")]), _c('div', {
    staticStyle: {
      "width": "400px"
    }
  }, _vm._l(_vm.optionsPeriodToView, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "d-flex-between font-weight-bolder"
    }, [_c('div', [_vm._v(_vm._s(_vm.$t("packageConfig.columns.".concat(key))) + ":")]), typeof value.value === 'number' ? _c('div', [_c('span', {}, [_vm._v(_vm._s(_vm.formatCurrency(value.value)))]), value.limit ? _c('span', {}, [_vm._v(" / " + _vm._s(_vm.formatCurrency(value.limit)))]) : _vm._e()]) : _c('div', [_vm._v(" " + _vm._s(value.value) + " ")])]);
  }), 0)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import sortBy from 'lodash/sortBy'

import { apiPackageConfig } from '@/api/'

export default {
  namespaced: true,
  state: {
    packageConfigs: [],
  },
  getters: {
    getPackageConfigs: state => state.packageConfigs,
    getMinPackageConfig: state => {
      let minPriceGiftPackage = sortBy(state.packageConfigs.filter(item => item.isGift), 'price')[0]
      if (!minPriceGiftPackage) {
        minPriceGiftPackage = sortBy(state.packageConfigs, 'price')[0]
      }
      return minPriceGiftPackage
    },
  },
  mutations: {
    SET_PACKAGE_CONFIGS(state, value) {
      state.packageConfigs = value
    },
  },
  actions: {
    getPackageConfigs({ commit }, params) {
      return new Promise((resolve, reject) => {
        apiPackageConfig.findAllPackageConfigs(params)
          .then(res => {
            commit('SET_PACKAGE_CONFIGS', res.data.items)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}

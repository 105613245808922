import { apiAuth } from '@/api/'

export default {
  namespaced: true,
  state: {
    username: null,
    email: null,
    changePwKey: null,
  },
  getters: {
    getUsername(state) {
      return state.username
    },
    getEmail(state) {
      return state.email
    },
    getChangePwKey(state) {
      return state.changePwKey
    },
  },
  mutations: {
    SET_USERNAME(state, payload) {
      state.username = payload
    },
    SET_EMAIL(state, payload) {
      state.email = payload
    },
    SET_CHANGE_PW_KEY(state, payload) {
      state.changePwKey = payload
    },
  },
  actions: {
    forgotPassword({ commit }, payload) {
      return apiAuth.forgotPassword(payload).then(res => {
        commit('SET_USERNAME', res.username)
      })
    },
    forgotPasswordByUsername({ commit }, payload) {
      return apiAuth.forgotPasswordByUsername(payload).then(res => {
        commit('SET_USERNAME', res.username)
        commit('SET_EMAIL', res.email)
      })
    },
    validateOtp({ commit }, payload) {
      return apiAuth.validateOtp(payload).then(res => {
        commit('SET_CHANGE_PW_KEY', res.changePwKey)
      })
    },

    resetPassword(ctx, payload) {
      return apiAuth.resetPassword(payload)
    },
  },
}

import { ref } from '@vue/composition-api'

export default function useSupportReqHandle() {
  const status = ref([
    'PENDING',
    'COMPLETED',
    'REJECTED',
    'SELF_HANDLED',
    'ALL',
  ])
  const airlineSupports = ref(['VJ'])

  return {
    status, airlineSupports,
  }
}

import { ref, computed, watch } from '@vue/composition-api'

import { apiPackageConfig } from '@/api'
import store from '@/store'

import useToast from '@useToast'

export default function usePackgageConfig() {
  const { toastSuccess, toastError } = useToast()

  const searchText = ref('')
  const resultArray = ref([])
  const currentPage = ref(1)
  const sizePerPage = ref(20)
  const loading = ref(false)
  const totalItems = ref(0)

  const fromItem = computed(() => sizePerPage.value * (currentPage.value - 1) + 1)
  const toItem = computed(() => (sizePerPage.value * (currentPage.value)) >= totalItems.value ? totalItems.value : (sizePerPage.value * (currentPage.value)))
  const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])

  const tableColumns = ref(['name', 'type', 'price', 'isActive', 'action'])

  function fetchPackageConfigs() {
    loading.value = true
    const params = {
      searchText: searchText.value || '',
      page: currentPage.value || 1,
      size: sizePerPage.value || 20,
      enable: isRoleF2.value ? true : undefined,
    }
    store.dispatch('app-package-config/getPackageConfigs', params)
      .then(res => {
        resultArray.value = res.data.items
        totalItems.value = res.data.count
        const allowPaymentQR = res.data?.allowPaymentQR || false
        store.dispatch('userStore/setAllowPaymentQR', allowPaymentQR)
      })
      .catch(() => {
        toastError('Lỗi tải dữ liệu')
      })
      .finally(() => {
        loading.value = false
      })
  }
  const getMinPricePackageConfig = computed(() => store.getters['app-package-config/getMinPackageConfig'])

  async function giftPackages(payload) {
    loading.value = true
    try {
      const res = await apiPackageConfig.giftPackages(payload)
      toastSuccess('Tặng gói thành công')
      return res
    } catch (error) {
      console.error({ error })
      toastError('Lỗi tặng gói')
      return error
    } finally {
      loading.value = false
    }
  }

  async function updatePackageConfig(id, item) {
    loading.value = true
    const payload = {
      ...item,
    }
    try {
      await apiPackageConfig.updatePackageConfigs(id, payload)
      toastSuccess('Cập nhật thành công')
    } catch (error) {
      toastError('Lỗi tải dữ liệu')
    } finally {
      loading.value = false
    }
  }

  async function savePackageConfig(id, payload) {
    loading.value = true
    try {
      if (id) {
        await apiPackageConfig.updatePackageConfigs(id, payload)
      } else {
        await apiPackageConfig.createPackageConfig(payload)
      }
      toastSuccess('Thành công')
    } catch (error) {
      toastError(error)
    } finally {
      loading.value = false
    }
  }
  async function deletePackageConfig(id) {
    loading.value = true
    try {
      await apiPackageConfig.deletePackageConfig(id)
      toastSuccess('Cập nhật thành công')
    } catch (error) {
      toastError(error)
    } finally {
      loading.value = false
    }
  }

  watch([currentPage, sizePerPage, searchText], () => {
    fetchPackageConfigs()
  })

  async function changeActive(item) {
    await updatePackageConfig(item.id, { enable: !item.enable })
    fetchPackageConfigs()
  }
  return {
    searchText,
    resultArray,
    currentPage,
    sizePerPage,
    toItem,
    fromItem,
    loading,
    totalItems,
    tableColumns,
    fetchPackageConfigs,
    changeActive,
    updatePackageConfig,
    savePackageConfig,
    deletePackageConfig,
    getMinPricePackageConfig,
    giftPackages,
  }
}

import { ref, computed, watch } from '@vue/composition-api'

import { apiPackageConfig } from '@/api'
import store from '@/store'

import useToast from '@useToast'

export default function usePackageCouponHandle() {
  const { toastSuccess, toastError } = useToast()

  // const searchText = ref('')
  const resultArray = ref([])
  const currentPage = ref(1)
  const sizePerPage = ref(20)
  const loading = ref(false)
  const totalItems = ref(0)

  const fromItem = computed(() => sizePerPage.value * (currentPage.value - 1) + 1)
  const toItem = computed(() => (sizePerPage.value * (currentPage.value)) >= totalItems.value ? totalItems.value : (sizePerPage.value * (currentPage.value)))

  const tableColumns = ref(['code', 'percentage', 'amountMax', 'isUsed', 'enable', 'action'])

  function fetchPackageCoupons() {
    loading.value = true
    const params = {
      page: currentPage.value || 1,
      size: sizePerPage.value || 20,
    }
    apiPackageConfig.fetchAllPackageCoupons(params)
      .then(res => {
        resultArray.value = res.data.items
        totalItems.value = res.data.count
        const allowPaymentQR = res.data?.allowPaymentQR || false
        store.dispatch('userStore/setAllowPaymentQR', allowPaymentQR)
      })
      .catch(() => {
        toastError('Lỗi tải dữ liệu')
      })
      .finally(() => {
        loading.value = false
      })
  }

  async function createPackageCoupon(payload) {
    loading.value = true
    try {
      const res = await apiPackageConfig.createPackageCoupons(payload)
      toastSuccess('Tạo mã giảm giá thành công')
      return res
    } catch (error) {
      console.error({ error })
      toastError('Lỗi tạo mã giảm giá')
      return error
    } finally {
      loading.value = false
    }
  }

  async function updatePackageCoupons(id, item) {
    loading.value = true
    const payload = {
      ...item,
    }
    try {
      await apiPackageConfig.updatePackageCoupons(id, payload)
      toastSuccess('Cập nhật thành công')
    } catch (error) {
      toastError('Lỗi tải dữ liệu')
    } finally {
      loading.value = false
    }
  }

  watch([currentPage, sizePerPage], () => {
    fetchPackageCoupons()
  })

  async function changeActive(item) {
    await updatePackageCoupons(item.id, { enable: !item.enable })
    fetchPackageCoupons()
  }
  async function deletePackageCoupons(id) {
    loading.value = true
    await apiPackageConfig.deletePackageCoupons(id)
    fetchPackageCoupons()
  }
  const loadingCheckCouponCode = ref(false)
  async function checkCouponCode(code) {
    loadingCheckCouponCode.value = true
    return apiPackageConfig.getCouponsByCode(code).finally(() => {
      loadingCheckCouponCode.value = false
    })
  }
  return {
    // searchText,
    resultArray,
    currentPage,
    sizePerPage,
    toItem,
    fromItem,
    loading,
    totalItems,
    tableColumns,
    fetchPackageCoupons,
    changeActive,
    updatePackageCoupons,
    createPackageCoupon,
    deletePackageCoupons,

    // check coupon code
    checkCouponCode,
    loadingCheckCouponCode,
  }
}
